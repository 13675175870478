import React from 'react';
import { Search, Filter } from 'lucide-react';

interface NotesHeaderProps {
  onSearch: (query: string) => void;
  onFilterByTag: (tag: string) => void;
  availableTags: string[];
}

export const NotesHeader: React.FC<NotesHeaderProps> = ({
  onSearch,
  onFilterByTag,
  availableTags,
}) => {
  return (
    <div className="mb-6 space-y-4">
      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search notes..."
          onChange={(e) => onSearch(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Tags Filter */}
      <div className="flex flex-wrap gap-2">
        {availableTags.map(tag => (
          <button
            key={tag}
            onClick={() => onFilterByTag(tag)}
            className="px-3 py-1 rounded-full text-sm bg-gray-100 hover:bg-gray-200 transition-colors"
          >
            {tag}
          </button>
        ))}
      </div>
    </div>
  );
}; 