import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginForm } from './components/auth/LoginForm';
import { RegisterForm } from './components/auth/RegisterForm';
import { AuthLayout } from './components/layout/AuthLayout';
import { GuestLayout } from './components/layout/GuestLayout';
import { Dashboard } from './pages/Dashboard';
import { LandingPage } from './pages/LandingPage';
import { SessionProvider } from './providers/SessionProvider';
import { ContactUs } from './pages/ContactUs';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { AdminGuard } from './components/auth/AdminGuard';
import { Notes } from './pages/Notes';

function App() {
  return (
    <SessionProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route element={<GuestLayout />}>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin" element={
              <AdminGuard>
                <AdminDashboard />
              </AdminGuard>
            } />
            <Route path="/notes" element={<Notes />} />
          </Route>
        </Routes>
      </Router>
    </SessionProvider>
  );
}



export default App;