import React from 'react';
import { FileText, Plus } from 'lucide-react';
import { Button } from '../ui/Button';

const templates = [
  {
    id: 'meeting',
    name: 'Meeting Notes',
    template: `# Meeting Notes
Date: [Date]
Attendees: [Names]

## Agenda
1. 
2. 
3. 

## Action Items
- [ ] 
- [ ] 

## Notes
`
  },
  {
    id: 'todo',
    name: 'Todo List',
    template: `# Todo List
## High Priority
- [ ] 

## Medium Priority
- [ ] 

## Low Priority
- [ ] 
`
  },
  // Add more templates as needed
];

interface NoteTemplatesProps {
  onSelectTemplate: (template: string) => void;
}

export const NoteTemplates: React.FC<NoteTemplatesProps> = ({ onSelectTemplate }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {templates.map(template => (
        <button
          key={template.id}
          onClick={() => onSelectTemplate(template.template)}
          className="p-4 border border-gray-200 rounded-lg hover:border-blue-500 hover:shadow-md transition-all text-left"
        >
          <div className="flex items-center gap-2 mb-2">
            <FileText className="w-5 h-5 text-blue-500" />
            <span className="font-medium">{template.name}</span>
          </div>
          <p className="text-sm text-gray-500">
            {template.template.slice(0, 100)}...
          </p>
        </button>
      ))}
    </div>
  );
}; 