import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuthStore } from '../store/authStore';
import { Note } from '../types';
import { NotesList } from '../components/notes/NotesList';
import { NotesHeader } from '../components/notes/NotesHeader';
import { NoteTemplates } from '../components/notes/NoteTemplates';
import { ShareNoteModal } from '../components/notes/ShareNoteModal';
import { RichTextEditor } from '../components/notes/RichTextEditor';
import { Button } from '../components/ui/Button';
import { FileText, X } from 'lucide-react';

export const Notes: React.FC = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthStore(state => state.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [showTemplates, setShowTemplates] = useState(false);

  const fetchNotes = async () => {
    if (!user?.id) return;

    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setNotes(data || []);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [user]);

  const handleAddNote = async (note: Omit<Note, 'id' | 'user_id' | 'created_at' | 'updated_at'>) => {
    if (!user?.id) return;

    try {
      const { error } = await supabase
        .from('notes')
        .insert([
          {
            user_id: user.id,
            ...note,
          }
        ]);

      if (error) throw error;
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleUpdateNote = async (id: string, note: Partial<Note>) => {
    try {
      const { error } = await supabase
        .from('notes')
        .update({
          ...note,
          updated_at: new Date().toISOString(),
        })
        .eq('id', id);

      if (error) throw error;
      fetchNotes();
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleDeleteNote = async (id: string) => {
    try {
      const { error } = await supabase
        .from('notes')
        .delete()
        .eq('id', id);

      if (error) throw error;
      fetchNotes();
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const filteredNotes = notes.filter(note => {
    const matchesSearch = note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         note.content.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTag = !selectedTag || note.tags?.includes(selectedTag);
    return matchesSearch && matchesTag;
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">Notes</h1>
            <p className="mt-1 text-sm text-gray-500">
              Keep track of your important thoughts and ideas
            </p>
          </div>
          <Button
            onClick={() => setShowTemplates(!showTemplates)}
            variant="outline"
            className="flex items-center space-x-2"
          >
            <FileText className="w-4 h-4" />
            <span>Templates</span>
          </Button>
        </div>

        <NotesHeader
          onSearch={setSearchQuery}
          onFilterByTag={setSelectedTag}
          availableTags={Array.from(new Set(notes.flatMap(note => note.tags || [])))}
        />
      </div>

      {showTemplates && (
        <div className="mb-8 p-6 bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-900">Note Templates</h2>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setShowTemplates(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
          <NoteTemplates
            onSelectTemplate={(template) => {
              setNewContent(template);
              setIsAddingNew(true);
              setShowTemplates(false);
            }}
          />
        </div>
      )}

      <div className="bg-gray-50 rounded-xl p-6">
        <NotesList
          notes={filteredNotes}
          onAddNote={handleAddNote}
          onUpdateNote={handleUpdateNote}
          onDeleteNote={handleDeleteNote}
        />
      </div>
    </div>
  );
}; 